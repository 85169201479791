<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="question">
      <b-row>
        <b-col cols="12">
          <b-card>
            <h4>اطلاعات پرسش</h4>
            <div>
              <b-form>
                <b-row>

                  <!-- Field: productCategoryId -->
                  <b-col v-if="categories !== null"
                         cols="12"
                         md="6"
                  >
                    <b-form-group
                        label="دسته بندی"
                        label-for="productCategoryId"
                    >
                      <v-select
                          v-model="question.productCategoryId"
                          :options="categories"
                          label="name"
                          :reduce="name => name.productCategoryId"
                          :clearable="false"
                          input-id="productCategoryId"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: level -->
                  <b-col
                      cols="12"
                      md="6"
                  >
                    <b-form-group
                        label="سطح پرسش"
                        label-for="level"
                    >
                      <v-select
                          v-model="question.level"
                          :options="levelOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="isAvailable"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field:  question -->
                  <b-col
                      cols="12"
                      md="12"
                      class="mt-3"
                  >
                    <b-form-group
                        label="متن پرسش"
                        label-for="question"
                    >
                      <b-form-input
                          id="question"
                          v-model="question.question"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: answers -->
                  <b-col
                      cols="12"
                      class="mt-2"
                  >
                    <b-row>
                      <b-col cols="12">
                        <span class="mb-1">متن پاسخ ها را وارد کنید و گزینه صحیح را انتخاب کنید :</span>
                      </b-col>
                      <b-col v-for="(answer,idx) in question.answers" cols="12">
                        <b-form-radio
                            plain
                            @change="setToTrue(answer)"
                            class="my-2 d-inline-block"
                            :name="answer.id.toString()"
                            :id="answer.id.toString()"
                            :value="answer.id.toString()"
                            v-model="correct"
                        >
                        </b-form-radio>
                        <b-form-input v-model="answer.answer" class="mx-1 w-50 d-inline-block"/>
                      </b-col>
                    </b-row>
                  </b-col>

                </b-row>
              </b-form>

              <b-button
                  variant="primary"
                  class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="updateQuestion"
              >
                ثبت
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTab,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BAvatar,
  BOverlay,
  BFormRadio,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import NewCategoryProperty from "@/views/apps/e-commerce/e-commerce-add/NewCategoryProperty";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ProductCategoryGetAllCourseCategoriesRequest} from "@/libs/Api/Product";
import {
  ExamCreateRequest,
  ExamQuestionCreateRequest,
  ExamQuestionGetRequest,
  ExamQuestionUpdateRequest
} from "@/libs/Api/Exam";
import ckEditorMain from "@/views/components/ckEditorMain.vue";
import {win} from "leaflet/src/core/Browser";

export default {
  title: "ویرایش پرسش - پنل ادمین مکس",
  name: "QuestionUpdate",
  components: {
    NewCategoryProperty,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    ckEditorMain,
    BFormRadio,
  },
  data() {
    return {
      overlay: false,
      propertiesData: [],
      question:null,
      correct:null,
      defaultData: {
        question: "",
        level: 1,
        productCategoryId: 0,
        answers: [
          {
            id:1,
            answer: "",
            isCorrect: false
          },
          {
            id:2,
            answer: "",
            isCorrect: false
          },
          {
            id:3,
            answer: "",
            isCorrect: false
          },
          {
            id:4,
            answer: "",
            isCorrect: false
          }
        ]
      },
      levelOptions: [
        {label: 'آسان', value: 1},
        {label: 'متوسط', value: 2},
        {label: 'سخت', value: 3}
      ],
      base64ImageSrc: null,
      categories: null,
    }
  },
  async created() {
    await this.getQuestion();
    await this.getAllCourseCategories();
  },
  methods: {
    async getQuestion() {
      let _this = this;
      _this.overlay = true;
      let defaultGetData = {
        id:_this.$route.params.id,
      }

      let examQuestionGetRequest = new ExamQuestionGetRequest(_this);
      examQuestionGetRequest.setParams(defaultGetData);
      await examQuestionGetRequest.fetch(function (content) {
        _this.overlay = false;
        _this.question = content.data;
        _this.question.answers.forEach(el => {
          if (el.isCorrect){
            _this.correct = el.id;
          }
        })
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async updateQuestion() {
      let _this = this;
      _this.overlay = true;
      let examQuestionUpdateRequest = new ExamQuestionUpdateRequest(_this);
      examQuestionUpdateRequest.setParams(_this.question);
      await examQuestionUpdateRequest.fetch(function (content) {
        _this.overlay = false;
        if (content.isSuccess) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `عملیات انجام شد.`,
            },
          })
          window.location.reload();
          // _this.$router.push({name:'apps-exam-question-list'});
        } else {
          return _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات ناموفق`,
              variant: 'danger',
              text: `تمامی فیلد‌ها الزامی میباشند.`,
            },
          })
        }
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    setToTrue(answer){
      let answers = this.question.answers;
      // this.correct = null;
      answers.forEach(el => {
        el.isCorrect = false;
        if (el.id === answer.id){
          el.isCorrect = true;
          this.correct = answer.id;
        }
      });
    },
    async getAllCourseCategories() {
      let _this = this;
      _this.overlay = true;
      let defaultGetData = {
        pageNumber: 0,
        count: 0,
      }

      let productCategoryGetAllProductCategoriesRequest = new ProductCategoryGetAllCourseCategoriesRequest(_this);
      productCategoryGetAllProductCategoriesRequest.setParams(defaultGetData);
      await productCategoryGetAllProductCategoriesRequest.fetch(function (content) {
        _this.overlay = false;
        _this.categories = content.productCategories;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>

</style>