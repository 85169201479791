import WebServiceRequest from '../Api/WebServiceRequest'

class ExamCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Exam/CreateExam')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class ExamUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Exam/UpdateExam')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class ExamGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Exam/GetExam')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class ExamGetExamsRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Exam/GetExams')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class ExamDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Exam/Delete')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

// Questions
class ExamQuestionCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ExamQuestion/CreateQuestion')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class ExamQuestionUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ExamQuestion/UpdateQuestion')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class ExamQuestionGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ExamQuestion/GetQuestion')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class ExamQuestionGetQuestionsRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ExamQuestion/GetQuestions')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class ExamQuestionDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ExamQuestion/DeleteQuestion')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class ExamSetExamAvailableRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Exam/SetExamAvailable')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class ExamSetExamUnavailableRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Exam/SetExamUnavailable')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

export {
    ExamCreateRequest,
    ExamUpdateRequest,
    ExamGetRequest,
    ExamGetExamsRequest,
    ExamDeleteRequest,
    ExamQuestionCreateRequest,
    ExamQuestionDeleteRequest,
    ExamQuestionGetQuestionsRequest,
    ExamQuestionGetRequest,
    ExamQuestionUpdateRequest,
    ExamSetExamAvailableRequest,
    ExamSetExamUnavailableRequest
}